import { gql } from "@apollo/client";

import * as fragments from "./fragments";

export const BENEFICIAL_OWNER = gql`
	query BeneficialOwner($token: String!) {
		beneficialOwner(token: $token) {
			id
			...beneficialOwnerFields
		}
	}
	${fragments.BENEFICIAL_OWNER}
`;

export const COUNTRIES = gql`
	query {
		countries {
			id
			name
			callingCode
			isoAlpha2
		}
	}
`;

export const CURRENCIES = gql`
	query {
		currencies {
			id
			name
			abbreviation
			symbol
			decimalSeparator
			thousandsSeparator
		}
	}
`;

export const FEATURES = gql`
	query Features {
		features {
			name
			isEnabled
		}
	}
`;

export const PORTFOLIO_ORDER = gql`
	query PortfolioOrder($id: ID!) {
		portfolio {
			order(id: $id) {
				...orderFields
			}
		}
	}
`;

export const INVESTING_ENTITY = gql`
	query InvestingEntity {
		investingEntity {
			...investingEntityFields
		}
	}
	${fragments.INVESTING_ENTITY}
`;

export const ME = gql`
	query Me {
		me {
			...accountFields
		}
	}
	${fragments.ACCOUNT}
`;

export const ACCOUNT_NOTIFICATION = gql`
	query AccountNotification($type: NotificationType!) {
		me {
			id
			notification(type: $type) {
				...accountNotificationFields
			}
		}
	}
	${fragments.ACCOUNT_NOTIFICATION}
`;

export const INVESTING_ENTITY_NOTIFICATION = gql`
	query InvestingEntityNotification($type: NotificationType!) {
		investingEntity {
			id
			notification(type: $type) {
				...investingEntityNotificationFields
			}
		}
	}
	${fragments.INVESTING_ENTITY_NOTIFICATION}
`;

export const BENEFICIAL_OWNER_CONTACTS = gql`
	query BeneficialOwnerContacts {
		beneficialOwnerContacts {
			id
			firstName
			lastName
			entityName
			relationship
			email
			mobilePhone {
				phoneNumber
				callingCode
			}
		}
	}
`;

export const GEOCODE_PLACE_ID = gql`
	query GeocodePlaceId($placeId: String!, $sessionToken: String) {
		geocodePlaceId(placeId: $placeId, sessionToken: $sessionToken) {
			line1
			suburb
			postCode
			city
			country
			administrativeArea
		}
	}
`;

export const SEARCH_ADDRESS = gql`
	query SearchAddress(
		$query: String!
		$searchType: AddressSearchType!
		$sessionToken: String
	) {
		searchAddress(
			query: $query
			searchType: $searchType
			sessionToken: $sessionToken
		) {
			sessionToken
			results {
				placeId
				description
			}
		}
	}
`;

export const SEARCH_COMPANIES = gql`
	query SearchCompanies($countryId: ID!, $query: String!) {
		searchCompanies(countryId: $countryId, query: $query) {
			id
			countryId
			name
		}
	}
`;

export const SESSION = gql`
	query Session {
		session {
			...sessionFields
		}
	}
	${fragments.SESSION}
`;

export const INITIALIZE_APP = gql`
	query InitializationApp {
		me {
			...accountFields
		}
		session {
			...sessionFields
		}
	}
	${fragments.ACCOUNT}
	${fragments.SESSION}
`;

export const OPPORTUNITY_ASSET = gql`
	query OpportunityAsset($opportunitySlug: ID!, $assetSlug: String!) {
		opportunity(slug: $opportunitySlug) {
			... on Offer {
				slug
				fund {
					...fundFields
					asset(slug: $assetSlug) {
						...opportunityAssetFields
					}
				}
			}
		}
	}
	${fragments.FUND}
	${fragments.OPPORTUNITY_ASSET}
`;

export const PERMISSIONS = gql`
	query Permissions {
		permissions {
			mutationPermissions {
				mutation
			}
			typePermissions {
				type
			}
		}
	}
`;
